import { styled } from "@mui/material";
import { Box } from "@mui/system"; 
import { getCategories, getCategoryById, updateCategories } from "app/api/admin";
import { Breadcrumb, SimpleCard } from "app/components";
import { capitalizeFirstLetter, getImageFromUrl } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CategoriesForm from "./CategoriesForm";

const CategoriesUpdate = () => {
    const title = 'categories';

    const { categoryId, type } = useParams();

    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState({});


    useEffect(() => {
        setLoading(true);

        try {
            getCategoryById(categoryId).then((res) => {
                setCategory(res.data.result.data);
                getImageFromUrl(category.image).then((res) => {
                    console.log(res);
                })

                //   console.log();
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }, [])



    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const navigate = useNavigate();

    const currentAdmin = { name: "", image: null }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("photo", (state.image != null) ? state.image[0] : "")

        updateCategories(categoryId, formData).then((res) => {
            if (type == 1) {
                navigate("/form-categories");
            } else if (type == 2) {
                navigate("/print-categories");
            } else {
                navigate("/link-categories");
            }
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Categories Update" }]} />
            </Box>
            <SimpleCard title="Categories Form">
                <CategoriesForm handleSubmit={handleSubmit} tableData={{ name: category.name, image: category.image }} />
            </SimpleCard>
        </Container>
    );
};

export default CategoriesUpdate;
