/**
 * @author vijay.codeflix@gmail.com
 * admin api's
 */


import {axiosServerClientAdmin} from "./axiosServer";


/* setting api start */
/* setting api end */


/** print-price api start */
export const getSettings = () => axiosServerClientAdmin.get("print-price");

export const updateSettings = (payload) =>
axiosServerClientAdmin.put("print-price/1", payload, {
    headers: {
        "Content-Type": "application/json",
    },
});
/* print-price api end */

/* Category api start */

export const getCategories = (type) =>
axiosServerClientAdmin.get(`category?status=1&type=${type}`);
export const deleteCategoriesApi = (id) =>
axiosServerClientAdmin.delete("category/" + id);

export const sort = (data) =>
axiosServerClientAdmin.post("sort", data, {
  headers: {
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
  },
});

export const categoriesUpdateStatus = (id) =>
axiosServerClientAdmin.post("category-status/" + id + "?_method=PUT");
export const getCategoryById = (id) =>
  axiosServerClientAdmin.get("category/" + id + "?status=1");

  export const updateCategories = (id, payload) =>
  axiosServerClientAdmin.post("category/" + id + "?_method=PUT", payload);
  export const createCategories = (payload) =>
  axiosServerClientAdmin.post("category", payload);
 
/* sub-category api end */


/* sub-category api start */
 
export const getSubCategories = (type) =>
  axiosServerClientAdmin.get(`sub-category?status=1&type=${type}`);
export const getActiveSubCategories = () =>
  axiosServerClientAdmin.get("sub-category");
export const getSubCategoryById = (id) =>
  axiosServerClientAdmin.get("sub-category/" + id + "?status=1");
export const getActiveSubCategoryById = (id) =>
  axiosServerClientAdmin.get("sub-category/" + id);
export const createSubCategories = (payload) =>
  axiosServerClientAdmin.post("sub-category", payload);
export const updateSubCategories = (id, payload) =>
  axiosServerClientAdmin.post("sub-category/" + id + "?_method=PUT", payload);
export const subCategoriesUpdateStatus = (id) =>
  axiosServerClientAdmin.post("sub-category-status/" + id + "?_method=PUT");

export const deleteSubCategoriesApi = (id) =>
axiosServerClientAdmin.delete("sub-category/" + id);
/* sub-category api end */




 
/* link api start */
export const getLinks = () => axiosServerClientAdmin.get(`link?status=1`);

export const LinkUpdateStatus = (id) =>
  axiosServerClientAdmin.post("link-status/" + id + "?_method=PUT");

  export const deleteLinkApi = (id) => axiosServerClientAdmin.delete("link/" + id);

  export const updateLink = (id, payload) =>
  axiosServerClientAdmin.post("link/" + id + "?_method=PUT", payload);

  export const createLink = (payload) => axiosServerClientAdmin.post("link", payload);

  export const getLinkById = (id) =>
  axiosServerClientAdmin.get("link/" + id + "?status=1");
/* link api end */

/* form api start */

export const getForms = (type) =>
  axiosServerClientAdmin.get(`form?status=1&type=${type}`);

export const deleteFormApi = (id) => axiosServerClientAdmin.delete("form/" + id);

export const getFormById = (id) =>
axiosServerClientAdmin.get("form/" + id + "?status=1");

export const FormUpdateStatus = (id) =>
  axiosServerClientAdmin.post("form-status/" + id + "?_method=PUT");

 
  export const updateForm = (id, payload) =>
  axiosServerClientAdmin.post("form/" + id + "?_method=PUT", payload);

  export const createForm = (payload) => axiosServerClientAdmin.post("form", payload);

  export const createFormfield = (payload) =>
  axiosServerClientAdmin.post("form-field", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });

  export const deleteFormfield = (id) =>
  axiosServerClientAdmin.delete("form-field/" + id);
/* form api end */


/* user form  api start*/



export const addUserValue = (payload) =>
  axiosServerClientAdmin.post("user-form", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });
  
  export const convertToPdf = (payload) =>
  axiosServerClientAdmin.post("img-to-pdf", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });

  // export const createUserForm = (payload) =>
  // axiosServerClientAdmin.post("user-form-details", payload, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     "access-control-allow-origin": "*",
  //   },
  // });


  export const printFile = (payload) =>
  axiosServerClientAdmin.post("print-file", payload, {
    headers: {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
    },
  });
 

  export const getUserFormDetails = (id) =>
  axiosServerClientAdmin.get("user-form-details/" + id);


/* user form  api end*/


/* computer api start */
export const getComputerLabel = () => axiosServerClientAdmin.get("computer");

export const getComputerById = (id) => axiosServerClientAdmin.get("computer/" + id);

export const createComputerApi = (payload) =>
  axiosServerClientAdmin.post("newcomputer", payload);

export const updateComputer = (id, payload) =>
  axiosServerClientAdmin.post("computer/" + id + "?_method=PUT", payload);

export const deleteComputer = (id) =>
  axiosServerClientAdmin.delete("computer/" + id);
 
  
 
/* computer api end */