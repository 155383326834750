import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { deleteFormfield } from "app/api/admin";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 1, paddingRight: 1 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 1, textTransform: "capitalize" } },
  },
}));

const deleteField = (id) => {
  try {
    deleteFormfield(id).then((res) => {
      window.location.reload(false);
    }).catch((error) => {
      // alert("You can not delete this sub category because it's already used in another module.")
    });
  } catch (error) {
    console.log(error)

  }
}



const FieldTable = (formField) => {
  return (
    <Box
      overflow="auto"
    >

      <Typography
        variant="h3"
        sx={{
          padding: '30px 15px',
          // textAlign: 'center'
        }}
      >
        title
        {/* {translations.category} */}
      </Typography>
      <div 
      style={{
        padding: '0px 15px 25px',
      
      }}
      >


        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell align="left">Label</TableCell>
              <TableCell align="left">Field Type</TableCell>
              <TableCell align="center">Is Required</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(typeof formField.form.form_field !== 'undefined') && formField.form.form_field.map((field, index) => (
              <TableRow key={index}>
                <TableCell align="left">{field.label}</TableCell>
                <TableCell align="left">{field.field_type}</TableCell>
                <TableCell align="center">{field.is_required}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => { deleteField(field.id) }}>
                    <Icon color="error">close</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </div>
    </Box>
  );
};

export default FieldTable;
