import DragAndDropFormField from 'app/components/DragAndDropFormField'
import React, { useEffect, useState } from 'react'
import FieldPopUp from './FieldPopUp'
import { useParams } from 'react-router-dom';
import { getFormById } from 'app/api/admin';
import { H1, Span } from 'app/components/Typography';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import FieldTable from './FieldTable';
import ImageWithText from 'app/components/ImageWithText';

function FormsFieldUpdate() {

    const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'flex-start' }));

    const ContentBox = styled(Box)(() => ({
        height: '100%',
        width: '371px',
        height: '670px',
        padding: '32px',
        position: 'relative',
        background: 'rgba(0, 0, 0, 0.01)',
    }));

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState({});
    const [position, setPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });

    const { formId } = useParams();


    useEffect(() => {
        setLoading(true);

        try {
            getFormById(formId).then((res) => {
                setForms({ forms, sub_category_id: res.data.result.data.sub_category.id, sub_category_name: res.data.result.data.sub_category.name, ...res.data.result.data });

                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            // setSubCategory(error);
        }
    }, [])

    return (
        <div>
            <FlexBox >
                <ImageWithText setOpen={setOpen} forms={forms} setPosition = {setPosition} />
                {/* <DragAndDropFormField setOpen={setOpen} forms={forms} setPosition = {setPosition} /> */}
                <FieldTable form={forms} />
            </FlexBox>
            <FieldPopUp open={open} setOpen={setOpen} formId={formId} position={position} />
        </div>
    )
}

export default FormsFieldUpdate
